.header {
    width: 100%;
    height: 150px;
}

.header .circle {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    position: absolute;
    left: -10px;
    top: -40px;
    box-shadow: 0 0 1000000px 230px #7cdbfe4b;
}

.header .logo {
    width: 100px;
    height: inherit;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.header .logo img {
    width: 150px;
    height: 150px;
}