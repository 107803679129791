@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
}

body {
    background: #f6f6f8;
}

button:hover {
    opacity: 0.6;
    transition: opacity .7s;
}

a {
    text-decoration: none;
}