.footer {
    width: 100%;
    background-color: #161C28;
    margin-top: 60px;
    
}

.footer section {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.footer .info {
    padding: 30px;
}

.footer .logo {
    width: 150px;
    height: 150px;
    margin-left: -25px;
}

.footer .logo img {
    width: 150px;
    height: 150px;
}

.footer p {
    color: #A6A6A6;
    font-size: 18px;
    width: 100%;
    max-width: 286px;
    margin-top: 30px;
    line-height: 30px;
    font-weight: 500;
}

.redes_sociais {
    display: flex;
    width: 100%;
    max-width: 200px;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
}

.redes_sociais i {
    font-size: 45px;
    color: #7CDBFE;
    cursor: pointer;
}

.redes_sociais i:hover {
    opacity: 0.5;
    transition: opacity .2s;
}

.engrenagem {
    width: 100%;
    max-width: 700px;
    position: relative;
    overflow: hidden;
}

.engrenagem i {
    color: #7CDBFE;
    position: absolute;
}

.engrenagem i:nth-of-type(1) {
    font-size: 300px;
    bottom: -70px;
    right: -80px;
}

.engrenagem i:nth-of-type(2) {
    font-size: 100px;
    bottom: 30px;
    right: 250px;
}

.engrenagem i:nth-of-type(3) {
    font-size: 100px;
    bottom: 170px;
    right: 200px;
}

.engrenagem i:nth-of-type(4) {
    font-size: 70px;
    bottom: 150px;
    right: 320px;
}

.engrenagem i:nth-of-type(5) {
    font-size: 30px;
    top: 100px;
    right: 130px;
}

.engrenagem i:nth-of-type(6) {
    font-size: 30px;
    top: 100px;
    right: 330px;
}

.engrenagem i:nth-of-type(7) {
    font-size: 30px;
    bottom: 100px;
    right: 400px;
}

.engrenagem i:nth-of-type(8) {
    font-size: 30px;
    bottom: 20px;
    right: 360px;
}

.engrenagem i:nth-of-type(9) {
    font-size: 30px;
    top: 140px;
    right: 420px;
}

.border {
    width: 100%;
    border-top: 1px solid #5b5b5b;
    padding: 15px 5px;
    text-align: center;
}

.footer .border span {
    color: #A6A6A6;
}

@media screen and (max-width: 1050px) {
    .engrenagem {
        display: none;
    }
}